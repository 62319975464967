import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import Component from "@xavisoft/react-component";
import { connect } from "react-redux";
import { BRANCH_EDITOR_MODES } from "../constants";
import actions from '../actions';
import { errorToast } from "../toast";
import { hideLoading, showLoading } from "../loading";
import swal from "sweetalert";
import request from "../request";
import { delay } from "../utils";


class BranchEditor extends Component {

   close = () => {
      actions.closeBranchEditor();
   }

   submit = async () => {

      const txtName = document.getElementById('txt-name');
      const txtBody = document.getElementById('txt-body');

      const name = txtName.value;
      const body = txtBody.value;

      if (!name) {
         errorToast('Provide the name of your branch');
         return txtName.focus();
      }

      if (!body) {
         errorToast('Describe your branch');
         return txtBody.focus();
      }

      try {

         showLoading();

         if (this.props.branchEditorMode === BRANCH_EDITOR_MODES.ADD) {

            const data = { name, body };

            if (this.props.parentToNewBranch) {
               data.parent = this.props.parentToNewBranch.id;
            } else {
               data.parent = null;
            }

            const res = await request.post('/api/branches', data);

            actions.addBranch({
               ...data,
               ...res.data,
            });

         } else {
            
            const data = {
               set: {
                  name,
                  body
               }
            }

            const id = this.props.branchBeingEdited.id;
            await request.patch(`/api/branches/${id}`, data);

            actions.updateBranch(id, data.set);
            
         }

         actions.closeBranchEditor();

      } catch (err) {
         swal(String(err));
      } finally {
         hideLoading();
      }
   }

   async componentDidUpdate(prevProps) {

      if (this.props.branchEditorMode === BRANCH_EDITOR_MODES.EDIT && prevProps.branchEditorMode !== BRANCH_EDITOR_MODES.EDIT) {

         await delay(50);

         const { name, body } = this.props.branchBeingEdited || {};
         document.getElementById('txt-name').value = name;
         document.getElementById('txt-body').value = body;

      }
   }

   render() {

      let parentJSX;

      if (this.props.parentToNewBranch) {
         parentJSX = <p className="text-lg text-gray-500">
            Add branch under <b>{this.props.parentToNewBranch.name}</b>
         </p>
      }

      const shrink = this.props.branchEditorMode === BRANCH_EDITOR_MODES.EDIT ? true : undefined;

      return <Dialog open={!!this.props.branchEditorMode}>

         <DialogTitle>
            {this.props.branchEditorMode === BRANCH_EDITOR_MODES.ADD ? 'Add' : 'Edit' } branch
         </DialogTitle>

         <DialogContent>
            <div className="[&>*]:my-2">
               
               {parentJSX}

               <TextField
                  id="txt-name"
                  variant="standard"
                  size="small"
                  label="Name"
                  fullWidth
                  InputLabelProps={{ shrink }}
               />

               <TextField
                  id="txt-body"
                  variant="standard"
                  size="small"
                  label="Description"
                  fullWidth
                  multiline
                  InputLabelProps={{ shrink }}
               />
            </div>
         </DialogContent>

         <DialogActions>

            <Button onClick={this.submit} variant="contained">
               { this.props.branchEditorMode === BRANCH_EDITOR_MODES.ADD ? 'ADD' : 'SAVE'  }
            </Button>

            <Button onClick={this.close}>
               CANCEL
            </Button>
         </DialogActions>
      </Dialog>
   }
}

function mapStateToProps(state) {
   const { branchEditorMode, branchBeingEdited, parentToNewBranch } = state;
   return { branchEditorMode, branchBeingEdited, parentToNewBranch };
}

export default connect(mapStateToProps)(BranchEditor);