
import './App.css';

import { createTheme, ThemeProvider } from '@mui/material';
import { Provider } from 'react-redux';
import store from './store';
import palette from './palette';
import { init as initDeviceInfo } from './device';
import AppWrapper, { Route } from '@xavisoft/app-wrapper';
import Home from './pages/Home';
import Login from './pages/Login';
import Navbar from './components/Navbar';
import Signup from './pages/Signup';
import UpdatePassword from './components/UpdatePassword';
import Test from './pages/Test';
import PageNotFound from './pages/PageNotFound';
import ErrorBoundary from './components/ErrorBoundary';
import Footer from './components/Footer';
import VerifyAccount from './pages/VerifyAccount';
import Dashboard from './pages/Dashboard';
import BranchEditor from './components/BranchEditor';
import Documentation from './pages/Documentation';
import PrivacyPolicy from './pages/PrivacyPolicy';

const theme = createTheme({
  palette: {
    primary: {
      main: palette['--primary'],
      contrastText: 'white',
    },
    secondary: {
      main: palette['--accent-1'],
      contrastText: 'white',
    }
  }
});

function setDimensions() {
	
	const width = window.innerWidth + 'px';
	const height = window.innerHeight + 'px';

	document.documentElement.style.setProperty('--window-width', width);
	document.documentElement.style.setProperty('--window-height', height);

}

window.addEventListener('resize', setDimensions);
setDimensions();


initDeviceInfo();

let testRoute

if (process.env.NODE_ENV)
  testRoute = <Route path="/test" component={Test} />

function App() {
  return <ErrorBoundary>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <AppWrapper>
          <Navbar />

          <Route path="/" component={Home} />
          <Route path="/login" component={Login} />
          <Route path="/signup" component={Signup} />
          <Route path="/verify-account/:code" component={VerifyAccount} />
          <Route path="/dashboard" component={Dashboard} />
          <Route path="/documentation" component={Documentation} />
          <Route path="/privacy-policy" component={PrivacyPolicy} />
          {testRoute}

          <Route path="*" component={PageNotFound} />

          <UpdatePassword />
          <BranchEditor />

          <Footer />

        </AppWrapper>
      </ThemeProvider>
    </Provider>
  </ErrorBoundary>
}

export default App;
