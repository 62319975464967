
import Page from './Page';
import { css } from '@emotion/css';
import TextField from '@mui/material/TextField';
import { Button } from '@mui/material';
import { errorToast } from '../toast';
import { hideLoading, showLoading } from '../loading';
import request from '../request';
import swal from 'sweetalert';
import { HashLink } from 'react-router-hash-link';
import PasswordResetModal from '../components/PasswordResetModal';
import { MetaTags } from 'react-meta-tags';


const divloginCss = css({
   maxWidth: 400,
   border: '1px solid #ddd',
   padding: 20,

   '& > *': {
      margin: '10px auto !important',
   },

   '& a': {
      color: '#00e',
      textUnderlineOffset: 4,
      textDecoration: 'underline',
      textDecorationThickness: 2
   }
})


class Login extends Page {

   state = {
      passwordResetModalOpen: false
   }

   openPasswordResetModal = () => {
      return this.updateState({ passwordResetModalOpen: true });
   }

   closePasswordResetModal = () => {
      return this.updateState({ passwordResetModalOpen: false });
   }

   login = async () => {

      const txtEmail = document.getElementById('txt-email');
      const txtPassword = document.getElementById('txt-password');

      const email = txtEmail.value;
      const password = txtPassword.value;

      if (!email) {
         errorToast('Provide email');
         return txtEmail.focus();
      }

      if (!password) {
         errorToast('Provide password');
         return txtPassword.focus();
      }

      const data = { email, password };

      try {

         showLoading();

         await request.post('/api/login', data);
         const dashboardRoute = '/dashboard';
         window.App.redirect(dashboardRoute);

      } catch (err) {
         console.log(err);
         swal(String(err));
      } finally {
         hideLoading();
      }
      
   }

   _render() {

      return <>

         <MetaTags>
            <title>Login | Xavisoft BotMaker</title>
         </MetaTags>

         <div className="vh-align" 
            style={{
               height: 'calc(var(--window-height) - var(--navbar-height))',
               position: 'relative',
            }}
         >
            <div className={divloginCss}>

               <h4 className='text-teal-900 font-bold text-xl'>LOGIN</h4>

               <TextField
                  fullWidth
                  label="Email"
                  id='txt-email'
                  size='small'
                  variant='standard'
                  type="email"
               />
               
               <TextField
                  fullWidth
                  label="Password"
                  id='txt-password'
                  size='small'
                  variant='standard'
                  type="password"
               />

               <Button
                  fullWidth
                  size="large"
                  variant='contained'
                  onClick={this.login}
                  className="primary-bg"
               >LOGIN</Button>

               <div className='center-align'>
                  Don't have an account?
                  {" "}
                  <HashLink to="/signup">Sign Up</HashLink>
               </div>

               <div className='center-align'>
                  <HashLink to="" onClick={this.openPasswordResetModal}>
                     I forgot my password
                  </HashLink>
                  <PasswordResetModal 
                     open={this.state.passwordResetModalOpen}
                     close={this.closePasswordResetModal}
                  />
               </div>

            </div>

         </div>
      </>
   }
}

export default Login;