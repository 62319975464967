
import { Button, ClickAwayListener, Divider, Hidden, IconButton } from "@mui/material";
import Page from "./Page";
import { connect } from 'react-redux';
import MenuIcon from '@mui/icons-material/Menu';
import SideMenu from "../components/SideMenu";
import SettingsIcon from '@mui/icons-material/Settings';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import PlayIcon from '@mui/icons-material/PlayCircle';
import { MetaTags } from "react-meta-tags";
import swal from "sweetalert";
import { hideLoading, showLoading } from "../loading";
import request from "../request";
import actions from "../actions";
import ConfigureChatbot from "../components/ConfigureChatbot";
import Settings from "../components/Settings";
import TestRun from "../components/TestRun";

const sideMenuItems = [
   {
      id: 'configure-chat',
      caption: 'Configure chatbot',
      icon: <ChatBubbleOutlineIcon />
   },
   {
      id: 'settings',
      caption: 'Acccount Settings',
      icon: <SettingsIcon />
   },
   {
      id: 'test-run',
      caption: 'Test run',
      icon: <PlayIcon />
   }
];


class Dashboard extends Page {

   state = {
      mobileSideMenuShowing: false,
      sideMenuActiveId: sideMenuItems[0].id,
      headerHeight: 0,
   }

   showSideMenu = () => {
      return this.updateState({ mobileSideMenuShowing: true });
   }

   hideSideMenu = () => {
      return this.updateState({ mobileSideMenuShowing: false });
   }

   onSideMenuActiveChange = (sideMenuActiveId) => {
      return this.setState({ sideMenuActiveId , mobileSideMenuShowing: false});
   }

   fetchAccountData = async () => {
      try {

         showLoading();

         const res = await request.get('/api/businesses/logged');

         console.log(res.data);
         actions.setBusinessInfo(res.data);

      } catch (err) {
         swal(String(err));
      } finally {
         hideLoading();
      }
   }

   onDashboardHeaderResize = () => {
      const headerHeight =  document.getElementById('div-dashboard-header').clientHeight;
      return this.updateState({ headerHeight });
   }

   componentWillUnmount() {
      this._resizeObserver.disconnect();
   }

   componentDidMount() {
      super.componentDidMount();

      this.fetchAccountData();
      const resizeObserver = new window.ResizeObserver(this.onDashboardHeaderResize);
      this._resizeObserver = resizeObserver;

      resizeObserver.observe(document.getElementById('div-dashboard-header'));

   }
   
   _render() {

      let gridTemplateColumns;
      let btnToggleSideMenuJSX;
      let mobileSideMenuJSX;

      const sideMenuJSX = <SideMenu
         items={sideMenuItems}
         active={this.state.sideMenuActiveId}
         onChange={this.onSideMenuActiveChange}
         collapse={this.hideSideMenu}
      />

      if ([ 'md', 'lg', 'xl' ].includes(this.props.device.size)) {
         gridTemplateColumns = '300px auto';
      } else {
         gridTemplateColumns = 'auto';

         btnToggleSideMenuJSX = <IconButton onClick={this.showSideMenu}>
            <MenuIcon />
         </IconButton>

         if (this.state.mobileSideMenuShowing) {
            mobileSideMenuJSX = <ClickAwayListener onClickAway={this.hideSideMenu}>
               <div 
                  style={{ 
                     position: 'fixed',
                     top: 0,
                     left: 0,
                     width: 300,
                     bottom: 0,
                  }}
               >
                  {sideMenuJSX}
               </div>
            </ClickAwayListener>
         }
      }


      let tabJSX;

      if (this.props.business) {

         switch (this.state.sideMenuActiveId) {
            case 'configure-chat':
               tabJSX = <ConfigureChatbot />
               break;

            case 'settings':
               tabJSX = <Settings />
               break

            case 'test-run':
               tabJSX = <TestRun />
               break
         
            default:
               break;
         }
      } else {
         tabJSX = <div className="fill-container vh-align">
            <div>
               <p className="text-grey-900 text-xl">
                  Something went wrong
               </p>

               <Button onClick={this.fetchAccountData}>
                  RETRY
               </Button>
            </div>
         </div>
      }

      return <>
         <MetaTags>
            <title>Dashboard | Xavisoft BotMaker</title>
         </MetaTags>

         <div className="fill-container grid" style={{ gridTemplateColumns }}>
            
            <Hidden only={[ 'xs', 'sm' ]}>
               <div>
                  {sideMenuJSX}
               </div>
            </Hidden>

            <div>
               <div id="div-dashboard-header">
                  <h2 className="v-align text-3xl my-3 font-bold ml-3 md:ml-5">{btnToggleSideMenuJSX} Dashboard</h2>
                  <Divider />
               </div>

               <div style={{ height: `calc(var(--window-height) - ${this.state.headerHeight}px)`}}>
                  {tabJSX}
               </div>

            </div>

            {mobileSideMenuJSX}
               
         </div>
      </>
   }
}

function mapStateToProps(state) {
   return { 
      device: state.device, 
      business: state.businessInfo,
   };
}

export default connect(mapStateToProps)(Dashboard);