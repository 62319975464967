import Carousel from 'react-material-ui-carousel';
import screenshot_1 from '../media/img/chat-1.jpg';
import screenshot_2 from '../media/img/chat-2.jpg';
import screenshot_3 from '../media/img/chat-3.jpg';
import screenshot_4 from '../media/img/chat-4.jpg';
import screenshot_5 from '../media/img/chat-5.jpg';
import SamsungM04 from './SamsungM04';

const screenshots = [
	screenshot_1,
	screenshot_2,
	screenshot_3,
	screenshot_4,
	screenshot_5,
];



export default function DemoChatBotCarousel() {

	return <Carousel>
		{
			screenshots.map(path => {
				return <div className='vh-align'>

					<div className='m-10'>
						<SamsungM04>
							<img src={path} alt='' style={{ width: '100%' }} /> 
						</SamsungM04>
					</div>
				</div>

			})
		}
	</Carousel>
}