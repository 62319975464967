
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";


Sentry.init({
   dsn: 'https://37aaf1aa3f554af6b009fdf7354af57f@o4504122577059840.ingest.sentry.io/4505006764523520',
   integrations: [new BrowserTracing()],
   tracesSampleRate: 1.0,
});

function error(err) {
   console.error(err)
   Sentry.captureException(err);
}

function log(msg) {
   console.log(msg)
   Sentry.captureMessage(msg);
}

const logger = {
   error,
   log,
};


export default logger;