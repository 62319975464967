import { Divider, IconButton } from "@mui/material";
import Component from "@xavisoft/react-component";
import { connect } from "react-redux";
import EditIcon from '@mui/icons-material/Edit';
import AccountInfoEditor from "./AccountInfoEditor";
import IntegrationDetailsEditor from "./IntegrationDetailsEditor";
import CopyIcon from '@mui/icons-material/ContentCopy';
import VisibilityOnIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { successToast } from "../toast";

function Label(props) {
   return <label className="text-[#596780] block text-xs font-bold">
      {props.children}
   </label>
}

class LabelValue extends Component {

   state = {
      visible: false,
   }

   toggleVisibility = () => {
      const visible = !this.state.visible;
      return this.updateState({ visible })
   }

   render() {

      let gridTemplateColumns = '';
      let toggleVisibilityJSX, copyTextJSX;
      let caption;

      if (this.props.caption) {

         caption = this.props.caption;

         if (this.props.secret) {

            let Icon;

            if (this.state.visible) {
               Icon = VisibilityOffIcon;
            } else {
               Icon = VisibilityOnIcon;
               caption = '●●●●●●';
            }

            toggleVisibilityJSX = <IconButton onClick={this.toggleVisibility}>
               <Icon />
            </IconButton>

            gridTemplateColumns += ' 40px';

         }

         if (this.props.copyable) {

            copyTextJSX = <IconButton
               onClick={
                  async () => {
                     await navigator.clipboard.writeText(this.props.caption);
                     successToast('Copied to clipboard!');
                  }
               }
            >
               <CopyIcon />
            </IconButton>

            gridTemplateColumns += ' 40px';
         }
      } else {
         caption = '(not set)'
      }

      return <div>

         <Label>{this.props.label}</Label>
         
         <div 
            className="grid max-w-[400px]"
            style={{
               columnGap: 5,
               gridTemplateColumns: `auto${gridTemplateColumns}`
            }}
         >
            <label 
               className="bg-gray-300 text-gray-700 px-3 py-2 my-2 inline-block truncate" 
               style={{ 
                  borderRadius: 5
               }}
            >
               {caption}
            </label>

            {toggleVisibilityJSX}
            {copyTextJSX}
         </div>
      </div>
   }
}

class Settings extends Component {

   state = {
      accountInfoEditorOpen: false,
      integrationDetailsEditorOpen: false,
   }

   openAccountInfoEditor = () => {
      return this.updateState({ accountInfoEditorOpen: true });
   }

   hideAccountInfoEditor = () => {
      return this.updateState({ accountInfoEditorOpen: false });
   }

   openIntegrationDetailsEditor = () => {
      return this.updateState({ integrationDetailsEditorOpen: true });
   }

   hideIntegrationDetailsEditor = () => {
      return this.updateState({ integrationDetailsEditorOpen: false });
   }

   render() {

      let webhookLinkJSX;

      if (this.props.business.wa_token) {

         const url = `https://botmaker.xavisoft.co.zw/api/webhooks/whatsapp/${this.props.business.id}`;

         webhookLinkJSX = <LabelValue
            label="WEBHOOK URL"
            caption={url}
            copyable
         />
      }

      return <div className="fill-container">
         <div className="px-5">

            <h4 className="text-emerald-900 text-capitalize">
               ACCOUNT INFO

               <IconButton onClick={this.openAccountInfoEditor}>
                  <EditIcon />
               </IconButton>
            </h4>

            <h1 className="text-black text-xl">{this.props.business.name}</h1>
            <h1 className="text-gray-600 text-lg">{this.props.business.email}</h1>
            <h1 className="text-gray-600 text-lg">{this.props.business.phone}</h1>

            <AccountInfoEditor
               open={this.state.accountInfoEditorOpen}
               close={this.hideAccountInfoEditor}
               name={this.props.business.name}
               phone={this.props.business.phone}
            />

            <Divider className="my-4" />

            <h4 className="text-emerald-900 text-capitalize">
               INTEGRATION DETAILS

               <IconButton onClick={this.openIntegrationDetailsEditor}>
                  <EditIcon />
               </IconButton>
            </h4>

            <div style={{ fontFamily: 'Helvetica' }}>
               <LabelValue
                  label="WA ID"
                  caption={this.props.business.wa_phone_id}
                  copyable
               />

               <LabelValue
                  label="WA TOKEN"
                  caption={this.props.business.wa_token}
                  copyable
                  secret
               />

               <LabelValue
                  label="WA CLIENT SECRET"
                  caption={this.props.business.wa_client_secret}
                  copyable
                  secret
               />

               {webhookLinkJSX}

            </div>

            <IntegrationDetailsEditor
               open={this.state.integrationDetailsEditorOpen}
               close={this.hideIntegrationDetailsEditor}
               wa_phone_id={this.props.business.wa_phone_id}
               wa_token={this.props.business.wa_token}
               wa_client_secret={this.props.business.wa_client_secret}
            />

         </div>
      </div>
   }
}

function mapStateToProps(state) {
   return { 
      // device: state.device, 
      business: state.businessInfo,
   };
}

export default connect(mapStateToProps)(Settings)