
import { Button } from '@mui/material';
import Component from '@xavisoft/react-component';
import { connect } from 'react-redux';
import { flattenBranches } from '../utils';
import { ReactGA } from '../analytics';


const MESSAGE_DIRECTIONS = {
   INCOMING: 'incoming',
   OUTGOING: 'outgoing'
}

function Message(props) {

   const textAlign = props.direction === MESSAGE_DIRECTIONS.INCOMING ? 'left' : 'right';

   return <div style={{ textAlign }}>
      <div className='rounded-lg text-sm bg-gray-100 inline-block text-left m-2 p-2' style={{ minWidth: 100, maxWidth: '70%' }}>
         {props.body}
      </div>
   </div>
}

class TestRun  extends Component {

   state = {
      chatHistory: []
   }

   updateState(updates) {
      return new Promise((resolve) => {
         const newState = { ...this.state, ...updates };
         this.setState(newState, resolve);
      });
   }

   addMessageToChat = async (body, direction) => {

      const chatHistory = [ ...this.state.chatHistory, { body, direction } ];
      await this.updateState({ chatHistory });

      const divChat = document.getElementById('div-chat');
      divChat.scrollTo(0, divChat.scrollHeight);

   }

   processMessage = (id=null) => {

      const responseData = {};

      const { branches, initial_message } = this.props.business;

      if (id === null) {
         responseData.message = initial_message;
         responseData.branches = branches;
      } else {
         const flattenedBranches = flattenBranches(branches);
         
         const [ targetBranch ] = flattenedBranches.filter(item => item.id === id);

         console.log(targetBranch);

         if (targetBranch) {
            responseData.message = targetBranch.body;
            responseData.branches = targetBranch.branches || [];
         } else {
            responseData.message = initial_message;
            responseData.branches = branches;
         }

      }

      const body = <>
         <p>{responseData.message}</p>

         {
            responseData.branches.map(branch => {
               return <div>
                  <Button 
                     onClick={
                        async () => {
                           const body = branch.name;
                           const direction = MESSAGE_DIRECTIONS.OUTGOING;
                           await this.addMessageToChat(body, direction)
                           this.processMessage(branch.id);
                        }
                     }
                     style={{
                        textTransform: 'none',
                        color: '#0000EE'
                     }}
                  >
                     {branch.name}
                  </Button>
               </div>
            })
         }
      </>

      const direction = MESSAGE_DIRECTIONS.INCOMING;
      return this.addMessageToChat(body, direction);
   }

   onKeyUp = async (e) => {
      if (e.keyCode !== 13)
         return;

      const txtMessage = document.getElementById('txt-message');
      const message = txtMessage.value;

      if (!message)
         return;

      txtMessage.value = '';

      await this.addMessageToChat(message, MESSAGE_DIRECTIONS.OUTGOING);
      this.processMessage();
      
   }

   componentDidMount() {
      ReactGA.event({
         category: 'DASHBOARD',
         action: "OPEN_TEST_RUN",
      });

      document.getElementById('txt-message').focus();
   }

   render() {

      const chatHistoryJSX = this.state.chatHistory.map(message => <Message {...message} />);

      return <div 
         className='fill-container grid'
         style={{
            gridTemplateRows: 'auto 90px'
         }}
      >

         <div style={{ overflow: 'auto' }} id='div-chat' className='px-5'>

            <h4 className='text-3xl text-gray-500'>Say "Hi" to your chatbot</h4>

            {chatHistoryJSX}
         </div>

         <div className='v-align px-5'>

            <div className='bg-teal-700 text-white px-5 py-2 rounded-xl' style={{ width: '100%' }}>

               <input
                  type='text'
                  id='txt-message'
                  onKeyUp={this.onKeyUp}
                  placeholder="Your message"
                  style={{
                     background: 'transparent',
                     outline: 'none',
                     border: 'none',
                     color: 'currentcolor'
                  }}
                  className='placeholder-white'
               />
            </div>

         </div>
         
      </div>
   }
}

function mapStateToProps(state) {
   const { businessInfo: business } = state;
   return { business };
}

export default connect(mapStateToProps)(TestRun);