
import { Button, Grid, Hidden, TextField } from '@mui/material';
import Page from './Page';

import { css } from '@emotion/css';
import { errorToast } from '../toast';
import swal from 'sweetalert';
import request from '../request';
import { hideLoading, showLoading } from '../loading';
import { HashLink } from 'react-router-hash-link';
import { MetaTags } from 'react-meta-tags';
import heroBg from '../media/img/hero.jpg';


const divSignupClass = css({

   '& > *': {
      margin: '10px auto !important',
   },

   margin: 20,

   '& a': {
      color: '#00e',
      textUnderlineOffset: 4,
      textDecorationThickness: 3,
   }
});


class Signup extends Page {

  

   signup = async () => {

      // extraction
      const txtName = document.getElementById('txt-name');
      const txtPhone = document.getElementById('txt-phone');
      const txtEmail = document.getElementById('txt-email');
      const txtPassword = document.getElementById('txt-password');
      const txtConfirm = document.getElementById('txt-confirm');

      const name = txtName.value;
      const phone = txtPhone.value;
      const email = txtEmail.value;
      const password = txtPassword.value;
      const confirm = txtConfirm.value;

      // presense check
      if (!name) {
         errorToast('Please provide your business name');
         return txtName.focus();
      }

      if (!phone) {
         errorToast('Please provide your WhatsApp phone number');
         return txtPhone.focus();
      }

      if (!email) {
         errorToast('Please provide your email address');
         return txtEmail.focus();
      }

      if (!password) {
         errorToast('Please choose a password for your account');
         return txtPassword.focus();
      }

      if (!confirm) {
         errorToast('Please reenter your password');
         return txtConfirm.focus();
      }

      if (confirm !== password) {
         errorToast('Your passwords do not match');
         txtPassword.value = '';
         txtConfirm.value = '';
         return txtPassword.focus();
      }


      // submit
      const data = {
         name,
         phone,
         email,
         password,
      }


      try {

         showLoading();
         await request.post('/api/businesses', data);

         // clear form
         const divLogin = document.querySelector(`.${divSignupClass}`);
         Array.from(divLogin.querySelector('input')).forEach(input => input.value = '');

         swal('You account has been created, check your email to verify.');

      } catch (err) {
         swal(String(err));
      } finally {
         hideLoading();
      }

   }

   _render() {
      return <>
         <MetaTags>
            <title>Sign Up | Xavisoft BotMaker</title>
         </MetaTags>

         <Grid container>
            <Hidden only={[ 'xs', 'sm' ]}>
               <Grid item xs={6}>
                  <div
                     style={{
                        height: 'calc(var(--window-height) - var(--navbar-height))',
                        backgroundSize: 'cover',
                        backgroundPosition: '50% 50%',
                        backgroundImage: `url(${heroBg})`
                     }}
                  />
               </Grid>
            </Hidden>

            <Grid item xs={12} md={6}>
               <div className='vh-align' style={{ height: 'calc(var(--window-height) - var(--navbar-height))'}}>
                  

                  <div 
                     style={{
                        width: '100%',
                        maxWidth: 400,
                     }}
                     className={divSignupClass}
                  >

                     <h4 className='text-teal-900 text-xl font-bold'>SIGNUP</h4>

                     <TextField
                        id="txt-name"
                        variant="standard"
                        size="small"
                        label="Business name"
                        fullWidth
                     />

                     <TextField
                        id="txt-phone"
                        variant="standard"
                        size="small"
                        label="Whatsapp phone number"
                        fullWidth
                        type='number'
                        helperText="With country code (without the +)"
                     />

                     <TextField
                        id="txt-email"
                        variant="standard"
                        size="small"
                        label="Email"
                        fullWidth
                        type="email"
                     />

                     <TextField
                        id="txt-password"
                        variant="standard"
                        size="small"
                        label="Password"
                        type="password"
                        fullWidth
                     />

                     <TextField
                        id="txt-confirm"
                        variant="standard"
                        size="small"
                        label="Confirm password"
                        type="password"
                        fullWidth
                     />

                     <Button 
                        variant="contained"
                        fullWidth
                        onClick={this.signup}
                        size="large"
                     >
                        SUBMIT
                     </Button>

                     <div className='center-align'>
                        Already have an account?
                        {" "}
                        <HashLink to="/login">Log in</HashLink>
                     </div>

                  </div>


               </div>
            </Grid>
         </Grid>
      </>
   }
}

export default Signup;