import { Chip, Divider, FormLabel, IconButton } from "@mui/material";
import Component from "@xavisoft/react-component";
import { connect } from "react-redux";
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import actions from "../actions";
import { BRANCH_EDITOR_MODES } from "../constants";
import ChatbotBranch from './ChatbotBranch';
import InitialMessageEditor from "./InitialMessageEditor";

class ConfigureChatbot extends Component {


   state = {
      initialMessageEditorOpen: false
   }

   openInitialMessageEditor = () => {
      return this.updateState({ initialMessageEditorOpen: true })
   }

   closeInitialMessageEditor = () => {
      return this.updateState({ initialMessageEditorOpen: false })
   }

   addBranch = () => {
      actions.openBranchEditor(BRANCH_EDITOR_MODES.ADD);
   }

   render() {

      const { initial_message, name, branches } = this.props.business;

      const initialMessage = initial_message || `Welcome to ${name}, how can we help you?`;

      let branchesJSX;

      if (branches.length > 0) {
         branchesJSX = branches.map(branch => <ChatbotBranch {...branch} />)
      } else {
         branchesJSX = <p className="text-gray-400 text-xl">
            No branches added to your chatbot.
         </p>
      }

      return <div className="fill-container" style={{ overflowY: 'auto' }}>
         <div className="p-3">
            <h4>CONFIGURE YOUR CHATBOT</h4>

            <FormLabel>Initial message</FormLabel>

            <div style={{ display: 'grid', gridTemplateColumns: 'auto 40px', columnGap: 10 }}>

               <div className="vh-align fill-container">
                  <Chip label={initialMessage} variant="outlined" style={{ width: '100%' }} />
               </div>

               <div className="vh-align fill-container">

                  <InitialMessageEditor
                     open={this.state.initialMessageEditorOpen}
                     close={this.closeInitialMessageEditor}
                     initial_message={initialMessage}
                  />

                  <IconButton onClick={this.openInitialMessageEditor}>
                     <EditIcon />
                  </IconButton>
               </div>
            </div>

            <Divider className="my-3" />

            <h4 className="text-lg font-extrabold">
               BRANCHES

               <IconButton size="large" onClick={this.addBranch}>
                  <AddIcon />
               </IconButton>
            </h4>

            {branchesJSX}


         </div>
      </div>
   }
}

function mapStateToProps(state) {
   return { business: state.businessInfo };
}

export default connect(mapStateToProps)(ConfigureChatbot);