
import ReactGA from "react-ga4";

const GA_MEASUREMENT_ID = "G-7D51LZ21EP"

ReactGA.initialize(GA_MEASUREMENT_ID, {
   testMode: process.env.NODE_ENV !== 'production',
});


export {
   ReactGA,
}