import { Button } from "@mui/material";
import BackIcon from '@mui/icons-material/ArrowBack'
import { HashLink as Link } from 'react-router-hash-link'
import Page from "./Page";
import { MetaTags } from "react-meta-tags";


class PageNotFound extends Page {

   _render() {

      return <>

         <MetaTags>
            <title>Page not found | Xavisoft BotMaker</title>
         </MetaTags>

         <div 
            style={{
               height: 'calc(var(--window-height) - var(--navbar-height))'
            }}
            className="vh-align"
         >

            <div>
               <h1>PAGE NOT FOUND</h1>
               <p className="grey-text">
                  You followed an invalid link.
               </p>

               <Button component={Link} to="/" startIcon={<BackIcon/>}>
                  go home
               </Button>
            </div>
         </div>
      </>
   }
}

export default PageNotFound;