import Component from "@xavisoft/react-component";
import AppBar from '@mui/material/AppBar';
import { css } from "@emotion/css";
import {  Button, Grid, Hidden } from "@mui/material";
import { Link } from '@xavisoft/app-wrapper';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import MobileNav from "./MobileNav";
import { connect } from "react-redux";




const navbarStyle = css({
   background: 'white !important',
});


const signupAndLoginLinks = [
   {
      caption: 'Login',
      to: '/login',
   },
   {
      caption: 'Sign Up',
      to: '/signup',
   },
   {
      caption: 'Documentation',
      to: '/documentation',
   },
   {
      caption: 'PRIVACY POLICY',
      to: '/privacy-policy',
   },
]



class Navbar extends Component {

   state = {
      mobileNavOpen: false,
   }

   openMobileNav = () => {
      return this.updateState({ mobileNavOpen: true })
   }

   closeMobileNav = () => {
      return this.updateState({ mobileNavOpen: false })
   }

   setDimensions = () => {
      const navbar = document.getElementById('navbar');
      
      const width = navbar.scrollWidth + 'px';
      const height = navbar.scrollHeight + 'px';

      document.documentElement.style.setProperty('--navbar-width', width);
      document.documentElement.style.setProperty('--navbar-height', height);
   }

   componentWillUnmount() {
      this.resizeOberver.disconnect();
   }

   componentDidMount() {
      const resizeOberver = new window.ResizeObserver(this.setDimensions);
      resizeOberver.observe(document.getElementById('navbar'));
      this.resizeOberver = resizeOberver;

      this.setDimensions();
   }

   
   render() {

      const currentRoute = this.props.currentRoute || '/';
      let jsx;

      if (currentRoute === '/dashboard') {
         jsx = <></>
      } else {

         const links = [
            {
               caption: 'Home',
               to: '/'
            },
         ];

         let actionButtonJSX;

         if (this.props.authenticated) {
            const dashboardLink = {
               caption: 'Dashboard',
               to: '/dashboard',
            }

            links.push(dashboardLink);
            actionButtonJSX = <span>actionButtonJSX</span>

         } else {
            links.push(...signupAndLoginLinks);
            actionButtonJSX = <>actionButtonJSX</>
         }

         jsx = <Grid container>

         <Grid item xs={8} sm={6} md={3}>
            <Link 
               to="/"
               style={{
                  textDecoration: 'none',
                  fontSize: '16pt',
                  color: 'var(--primary)'
               }}
               className="valign"
               color="primary"
            >
              
              <h2 className="text-4xl font-extrabold m-3">BotMaker</h2>
               
            </Link>
         </Grid>


         <Hidden only={[ 'xs', 'sm' ]}>
            <Grid item md={9}>
               <div
                  style={{
                     display: 'grid',
                     gridTemplateColumns: 'auto 120pt',
                  }}
                  className="fill-container"
               >
                  <div className="v-align" style={{ justifyContent: 'right' }}>
                     {
                        links.map(link => {

                           const color = link.to === currentRoute ? 'black' : 'grey';

                           return <Link
                              style={{
                                 display: 'inline-block',
                                 color,
                                 textTransform: 'uppercase',
                                 textDecoration: 'none',
                                 margin: 12,
                                 fontSize: '11pt',
                              }}
                              to={link.to}
                              onClick={link.onClick}
                              key={link.caption}
                           >{link.caption}</Link>
                        })
                     }
                  </div>
                  
                  <div className="v-align" style={{ padding: '8pt'}}>
                     {actionButtonJSX}
                  </div>
               </div>
                  
            </Grid>
         </Hidden>

         <Hidden mdUp only={[ 'md', 'lg', 'xl' ]}>
            <Grid item xs={4}>
               <div
                  style={{
                     display: 'flex',
                     alignContent: 'center',
                     justifyContent: 'right',
                  }}
                  className="fill-container"
               >
                  <Button onClick={this.openMobileNav}>
                     <MenuOpenIcon />
                  </Button>

                  <MobileNav
                     open={this.state.mobileNavOpen}
                     close={this.closeMobileNav}
                     links={links}
                  />
               </div>
            </Grid>
         </Hidden>
      </Grid>
      }

      return <AppBar id="navbar" className={navbarStyle}>
         {jsx}
      </AppBar>
   }
}

function mapStateToProps(state) {
   const { currentRoute, authenticated } = state;
   return { currentRoute, authenticated }
}

export default connect(mapStateToProps)(Navbar);