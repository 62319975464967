
import { ACTIONS } from "./constants";
import store from "./store";

function setDeviceInfo(device) {

   const action = {
      type: ACTIONS.SET_DEVICE_INFO,
      payload:  device
   }

   store.dispatch(action);

}

function setCurrentRoute(route) {

   const action = {
      type: ACTIONS.SET_CURRENT_ROUTE,
      payload:  { route }
   }

   store.dispatch(action);

}

function closeUpdatePasswordModal() {
   const action = {
      type: ACTIONS.CLOSE_UPDATE_PASSWORD_MODAL,
   }

   store.dispatch(action);
}

function openUpdatePasswordModal() {
   const action = {
      type: ACTIONS.OPEN_UPDATE_PASSWORD_MODAL,
   }

   store.dispatch(action);
}

function setBusinessInfo(business) {
   const action = {
      type: ACTIONS.SET_BUSINESS_INFO,
      payload: business,
   }

   store.dispatch(action);
}

function openBranchEditor(mode, branchBeingEdited, parentToNewBranch) {
   const action = {
      type: ACTIONS.OPEN_BRANCH_EDITOR,
      payload: {
         mode,
         branchBeingEdited,
         parentToNewBranch,
      },
   }

   store.dispatch(action);
}

function closeBranchEditor() {
   const action = {
      type: ACTIONS.CLOSE_BRANCH_EDITOR,
   }

   store.dispatch(action);
}


function addBranch(branch) {
   const action = {
      type: ACTIONS.ADD_BRANCH,
      payload: branch,
   }

   store.dispatch(action);
}

function updateBranch(id, updates) {

   console.log('update reducer...')
   const action = {
      type: ACTIONS.UPDATE_BRANCH,
      payload: { id, updates },
   }

   store.dispatch(action);
}

function deleteBranch(id) {
   const action = {
      type: ACTIONS.DELETE_BRANCH,
      payload: { id },
   }

   store.dispatch(action);
}

function updateBusinessInfo(changes) {
   const action = {
      type: ACTIONS.UPDATE_BUSINESS_INFO,
      payload: changes
   }

   store.dispatch(action);
}


const actions = {
   addBranch,
   closeBranchEditor,
   closeUpdatePasswordModal,
   deleteBranch,
   openBranchEditor,
   openUpdatePasswordModal,
   setBusinessInfo,
   setCurrentRoute,
   setDeviceInfo,
   updateBranch,
   updateBusinessInfo,
}


export default actions;