import { Button, Grid, Hidden } from "@mui/material";
import { connect } from "react-redux";
import DemoChatBotCarousel from "./DemoChatbotCarousel";
import { Link } from "@xavisoft/app-wrapper";

function AboutUs(props) {

   let pointerJSX;
   let somethingDifferentBelowPointerJSX;
   let somethingDifferentBelowCarouselJSX

   const needSomethingDifferentJSX = <div className="p-10">
      <div className="text-5xl text-teal-900 mb-5 font-bold">
         Need something different?
      </div>

      <Button variant="contained" size="large" className="rounded-3xl" component={Link} to="https://wa.me/263715919598">
         TALK TO US
      </Button>

   </div>

   if ([ 'xs', 'sm' ].includes(props.device.size)) {

      pointerJSX = <div className="text-5xl text-center m-5">
         👇🏽
      </div>

      somethingDifferentBelowCarouselJSX = needSomethingDifferentJSX;

   } else {

      pointerJSX = <div className="text-8xl m-5">
         👈🏽
      </div>

      somethingDifferentBelowPointerJSX = needSomethingDifferentJSX;

   }

   const slideShowJSX = <DemoChatBotCarousel />

   return <section id="about-section" className="bg-teal-100 md:py-20">
      <Grid container>
         
         <Hidden only={[ 'xs', 'sm' ]}>
            <Grid item xs={6}>
               <div className="fill-container">
                  {slideShowJSX}
               </div>
            </Grid>
         </Hidden>

         <Grid item xs={12} md={6}>
            <div className={`text-5xl md:text-6xl text-center md:text-left  font-extrabold m-5 mt-20  text-teal-950`}>
               Our platform helps you make bots like this one
            </div>
            {pointerJSX}
            {somethingDifferentBelowPointerJSX}
         </Grid>

         <Hidden only={[ 'md', 'lg', 'xl' ]}>
            <Grid item xs={12}>
               {slideShowJSX}
               {somethingDifferentBelowCarouselJSX}
            </Grid>
         </Hidden>
      </Grid>
   </section>
}

function mapStateToProps(state) {
   return { device: state.device }
}

export default connect(mapStateToProps)(AboutUs);