import { ACTIONS } from "./constants";
import { flattenBranches } from "./utils";


function deleteBranchById(branches=[], id) {

   const resultingBranches = branches.filter(branch => branch.id !== id);

   if (resultingBranches.length !== branches.length)
      return [ true, resultingBranches ];

   let filtered = false;

   for (let i in resultingBranches) {
      const branch = resultingBranches[i];
      const [ done, resultingSubBranches] = deleteBranchById(branch.branches, id);

      if (done) {
         branch.branches = resultingSubBranches;
         filtered = true;
         break;
      }
   }

   return [ filtered, resultingBranches];
}

function setDeviceInfoReducer(state, device) {
   return { ...state, device };
}

function setCurrentRouteReducer(state, payload) {
   const { route:currentRoute } = payload;
   return { ...state, currentRoute };
}

function setUpdatePasswordModalOpenStateReducer(state, updatePasswordModalOpen) {
   return { ...state, updatePasswordModalOpen };
}


function setBusinessInfoReducer(state, business) {

   // map
   const branchMap = new Map();

   business.branches.forEach(branch => {
      branchMap.set(branch.id, branch);
      branch.branches = [];
   });

   // build tree
   const topLevelBranches = business.branches.filter(item => item.parent === null);
   const nonTopLevelBranches = business.branches.filter(item => item.parent !== null);

   business.branches = topLevelBranches;

   nonTopLevelBranches.forEach(branch => {
      const parent = branchMap.get(branch.parent);

      if (parent) {
         parent.branches.push(branch);
      }
   });

   return { ...state, businessInfo: business };
}


function branchEditorStateReducer(state, payload={}) {

   const { 
      branchBeingEdited=null,
      mode: branchEditorMode=null,
      parentToNewBranch=null,
   } = payload;

   return { ...state, branchBeingEdited, branchEditorMode, parentToNewBranch }
}

function addBranchReducer(state, branch) {

   const branches = [ ...state.businessInfo.branches ]
   if (!branch.parent) {
      branches.push(branch);
   } else {
      
      const flattenedBranches = flattenBranches(branches);

      console.log({flattenedBranches, branch });

      for (let i in flattenedBranches) {
         
         const potentialParent = flattenedBranches[i];

         if (potentialParent.id === branch.parent) { console.log('We are here');
            if (!potentialParent.branches)
               potentialParent.branches = [];

            potentialParent.branches = [ ...potentialParent.branches, branch ];
            break;
         }
      }
   }

   const businessInfo = { ...state.businessInfo, branches };
   return { ...state, businessInfo };
}

function updateBranchReducer(state, payload) {

   const branches = [ ...state.businessInfo.branches ];
      
   const flattenedBranches = flattenBranches(branches);
   const { id, updates } = payload;

   for (let i in flattenedBranches) {
      
      const potentialTarget = flattenedBranches[i];

      if (potentialTarget.id === id) {

         for (let key in updates) {
            potentialTarget[key] = updates[key];
         }

         break;
      }
   }

   const businessInfo = { ...state.businessInfo, branches };
   return { ...state, businessInfo };
}


function deleteBranchReducer(state, id) {

   // eslint-disable-next-line
   const[ done, branches ]= deleteBranchById(state.businessInfo.branches, id);
   const businessInfo = { ...state.businessInfo, branches };
   return { ...state, businessInfo };

}


function updateBusinessInfoReducer(state, changes) {
   const businessInfo = { ...state.businessInfo, ...changes };
   return { ...state, businessInfo }
}



function reducer(state, action) {

   const { type, payload } = action;

   switch (type) {

      case ACTIONS.SET_DEVICE_INFO:
         return setDeviceInfoReducer(state, payload);

      case ACTIONS.SET_CURRENT_ROUTE:
         return setCurrentRouteReducer(state, payload);

      case ACTIONS.OPEN_UPDATE_PASSWORD_MODAL:
         return setUpdatePasswordModalOpenStateReducer(state, true);

      case ACTIONS.CLOSE_UPDATE_PASSWORD_MODAL:
         return setUpdatePasswordModalOpenStateReducer(state, false);

      case ACTIONS.SET_BUSINESS_INFO:
         return setBusinessInfoReducer(state, payload);

      case ACTIONS.OPEN_BRANCH_EDITOR:
         return branchEditorStateReducer(state, payload);
      
      case ACTIONS.CLOSE_BRANCH_EDITOR:
         return branchEditorStateReducer(state);

            
      case ACTIONS.ADD_BRANCH:
         return addBranchReducer(state, payload);
      
      case ACTIONS.UPDATE_BRANCH:
         return updateBranchReducer(state, payload);

      case ACTIONS.DELETE_BRANCH:
         return deleteBranchReducer(state, payload.id);
      
      case ACTIONS.UPDATE_BUSINESS_INFO:
         return updateBusinessInfoReducer(state, payload);

      default:
         console.info('Invalid action type:', type);
         return state;
   }
}


export default reducer;