
import Page from './Page';


class Test extends Page {

	_render() {
		return <>
		</>
	}
}


export default Test;