import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import Component from "@xavisoft/react-component";
import { errorToast } from "../toast";
import swal from "sweetalert";
import { hideLoading, showLoading } from "../loading";
import request from "../request";
import actions from "../actions";
import { delay } from "../utils";


export default class IntegrationDetailsEditor extends Component {

   submit = async () => {

      const txtWaId = document.getElementById('txt-wa-id');
      const txtWaToken = document.getElementById('txt-wa-token');
      const txtWaClientSecret = document.getElementById('txt-wa-client-secret');

      const wa_phone_id = txtWaId.value;
      const wa_token = txtWaToken.value;
      const wa_client_secret = txtWaClientSecret.value;

      if (!wa_phone_id) {
         errorToast('Whatsapp number ID is required');
         return txtWaId.focus();
      }

      if (!wa_token) {
         errorToast('Whatsapp token is required');
         return txtWaToken.focus();
      }

      if (!wa_client_secret) {
         errorToast('Whatsapp client secret is required');
         return txtWaToken.focus();
      }

      try {

         showLoading();
         
         const data = {
            set: {
               wa_phone_id,
               wa_token,
               wa_client_secret
            }
         }

         await request.patch('/api/businesses', data);

         actions.updateBusinessInfo(data.set);
         this.props.close();

      } catch (err) {
         swal(String(err));
      } finally {
         hideLoading();
      }
   }

   async componentDidUpdate(prevProps) {
      if (this.props.open && !prevProps.open) {
         await delay(50);
         document.getElementById('txt-wa-id').value = this.props.wa_phone_id;
         document.getElementById('txt-wa-token').value = this.props.wa_token;
         document.getElementById('txt-wa-client-secret').value = this.props.wa_client_secret;
      }
   }

   render() {

      return <Dialog open={this.props.open}>

         <DialogTitle>Account Info</DialogTitle>

         <DialogContent>
            <div className="[&>*]:my-2">
               <TextField
                  id="txt-wa-id"
                  variant="standard"
                  size="small"
                  label="WhatsApp Phone ID"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  type="number"
               />

               <TextField
                  id="txt-wa-token"
                  variant="standard"
                  size="small"
                  label="WhatsApp Token"
                  type="text"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
               />

               <TextField
                  id="txt-wa-client-secret"
                  variant="standard"
                  size="small"
                  label="WhatsApp Client Secret"
                  type="text"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
               />
            </div>
         </DialogContent>

         <DialogActions>
            <Button onClick={this.submit} variant="contained">
               SAVE
            </Button>
            <Button onClick={this.props.close}>
               CANCEL
            </Button>
         </DialogActions>
      </Dialog>
   }
}