import { connect } from "react-redux";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';


function Footer(props) {

   if (props.currentRoute === '/dashboard')
      return <></>

   return <footer className="bg-teal-900 text-white text-center py-5 text-xl">
      &copy; {new Date().getFullYear()}
      {" "}
      <a href="https://xavisoft.co.zw" 
         style={{
            fontSize: 'inherit',
            color: 'inherit',
            textDecoration: 'none',
            alignItems: 'center'
         }}
         className="inline-flex"
      >
         Xavisoft Digital <OpenInNewIcon className="ml-1" style={{ fontSize: 24 }} />
      </a>
   </footer>
}


function mapStateToProps(state) {
   const { currentRoute } = state;
   return { currentRoute }
}

export default connect(mapStateToProps)(Footer);