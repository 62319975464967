
import { Button, Divider } from '@mui/material'
import { Sidebar, Menu, MenuItem, ProSidebarProvider } from 'react-pro-sidebar';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import swal from 'sweetalert';
import request from '../request';
import { hideLoading, showLoading } from '../loading';
import HelpIcon from '@mui/icons-material/Help';

async function logout() {
   try {
      showLoading();
      await request.delete('/api/login');
      window.App.redirect('/');
   } catch (err) {
      swal(String(err))
   } finally {
      hideLoading();
   }
}

export default function SideMenu(props) {

   return <div className='bg-teal-900 text-white' style={{ height: 'var(--window-height)', position: 'relative' }}>
      <ProSidebarProvider>

         <Sidebar width='100%' backgroundColor='transparent'>

            <h1 className='v-align text-white text-2xl ml-5'>
               <img src='/favicon.ico' alt="" />
               <span className='inline-block ml-3 font-extrabold'>BotMaker</span>
            </h1>

            <Divider className='mb-4' />

            <Menu>
               {
                  props.items.map(item => {
                     return <MenuItem 
                        onClick={() => props.onChange(item.id)}
                        className={item.id === props.active ? 'font-extrabold' : ''}
                        key={item.id}
                        icon={item.icon}
                     >
                        {item.caption}
                     </MenuItem>
                  })
               }
            </Menu>

            <Divider className="my-3" />

            <Menu>
               <MenuItem 
                  onClick={() => window.App.redirect('/documentation')}
                  icon={<HelpIcon />}
               >
                  Guide
               </MenuItem>
            </Menu>

         </Sidebar>
      </ProSidebarProvider>


      <div style={{ position: 'absolute', bottom: 30, left: 0, right: 0, textAlign: 'center' }}>
         <Button startIcon={<ArrowBackIosIcon />} className='text-white' size='large' onClick={logout}>
            LOGOUT
         </Button>
      </div>
   </div>
}