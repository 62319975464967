import {  Divider, IconButton } from "@mui/material";
import Component from "@xavisoft/react-component";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import actions from "../actions";
import { BRANCH_EDITOR_MODES } from "../constants";
import { requestConfirmation } from "../utils";
import { hideLoading, showLoading } from "../loading";
import swal from "sweetalert";
import request from "../request";




export default class ChatbotBranch extends Component {

   state = {
      expanded: false,
   }

   toggleExpansion = () => {
      const expanded = !this.state.expanded;
      return this.updateState({ expanded })
   }

   
   addBranch = () => {
      const { id, name } = this.props;
      const parentToNewBranch = { id, name };

      actions.openBranchEditor(BRANCH_EDITOR_MODES.ADD, null, parentToNewBranch);
   }

   editBranch = () => {
      const { id, name, body } = this.props;
      const branchBeingEdited = { id, name, body };

      actions.openBranchEditor(BRANCH_EDITOR_MODES.EDIT, branchBeingEdited);
   }

   deleteBranch = async () => {

      // get confirmation
      const question = `Do you really want to delete the branch "${this.props.name}"`;
      const confirmButtonCaption = 'Yes, delete';
      const cancelButtonCaption = 'Cancel';

      const confirmation = await requestConfirmation({ question, confirmButtonCaption, cancelButtonCaption });
      if (!confirmation)
         return;

      // delete
      try {

         showLoading();

         const id = this.props.id;
         await request.delete(`/api/branches/${id}`);

         actions.deleteBranch(id);

      } catch (err) {
         swal(String(err));
      } finally {
         hideLoading();
      }
   }


   render() {

      let expandedJSX, ToggleExpansionIcon;

      if (this.state.expanded) {

         ToggleExpansionIcon = KeyboardArrowDownIcon;

         let subOptionsJSX;

         if (this.props.branches && this.props.branches.length > 0) {
            subOptionsJSX = <>

               <Divider className="my-4" />

               <h6 className="text-gray-600 font-bold text-sm mt-1 mb-3">Sub-options</h6>

               {
                  this.props.branches.map(item => <ChatbotBranch {...item} key={item.id} />)
               }
            </>
         }

         expandedJSX = <>
            
            <div className="text-lg text-gray-500 px-4 my-3" style={{ borderLeft: '5px solid grey', }}>
               {this.props.body}
            </div>

            {subOptionsJSX}
         </>
      } else {
         ToggleExpansionIcon = KeyboardArrowRightIcon;
      }

      return <div
         style={{
            display: 'grid',
            gridTemplateColumns: '40px auto',
         }}
      >
         <div>
            <IconButton onClick={this.toggleExpansion} className="mt-1 text-3xl">
               <ToggleExpansionIcon />
            </IconButton>
         </div>

         <div>
            <div className="text-grey-900 text-xl pt-2">
               {this.props.name}

               <IconButton className="ml-5" size="small" onClick={this.addBranch}>
                  <AddIcon />
               </IconButton>

               <IconButton size="small" onClick={this.editBranch}>
                  <EditIcon />
               </IconButton>

               <IconButton size="small" onClick={this.deleteBranch}>
                  <DeleteIcon />
               </IconButton>
            </div>
            {expandedJSX}
         </div>
      </div>
   }
}