import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import Component from "@xavisoft/react-component";
import { errorToast } from "../toast";
import swal from "sweetalert";
import { hideLoading, showLoading } from "../loading";
import request from "../request";
import actions from "../actions";
import { delay } from "../utils";


export default class AccountInfoEditor extends Component {

   submit = async () => {

      const txtName = document.getElementById('txt-name');
      const txtPhone = document.getElementById('txt-phone');

      const name = txtName.value;
      const phone = txtPhone.value;

      if (!name) {
         errorToast('Business name is required');
         return txtName.focus();
      }

      if (!phone) {
         errorToast('Phone number is required');
         return txtPhone.focus();
      }

      try {

         showLoading();

         await request.patch('/api/businesses', {
            set: {
               name,
               phone,
            }
         });

         actions.updateBusinessInfo({ name, phone });
         this.props.close();

      } catch (err) {
         swal(String(err));
      } finally {
         hideLoading();
      }
   }

   async componentDidUpdate(prevProps) {
      if (this.props.open && !prevProps.open) {
         await delay(50);
         document.getElementById('txt-name').value = this.props.name;
         document.getElementById('txt-phone').value = this.props.phone;
      }
   }

   render() {

      return <Dialog open={this.props.open}>

         <DialogTitle>Account Info</DialogTitle>

         <DialogContent>
            <div className="[&>*]:my-2">
               <TextField
                  id="txt-name"
                  variant="standard"
                  size="small"
                  label="Business name"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
               />

               <TextField
                  id="txt-phone"
                  variant="standard"
                  size="small"
                  label="WhatsApp phone number"
                  type="number"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
               />
            </div>
         </DialogContent>

         <DialogActions>
            <Button onClick={this.submit} variant="contained">
               SAVE
            </Button>
            <Button onClick={this.props.close}>
               CANCEL
            </Button>
         </DialogActions>
      </Dialog>
   }
}