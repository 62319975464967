import { MetaTags } from "react-meta-tags";
import AboutUs from "../components/AboutUs";
import Hero from "../components/Hero";
import Page from "./Page";


class Home extends Page {

   _render() {

      return <>

         <MetaTags>
            <title>Xavisoft BotMaker | Create your own WhatsApp bot in minutes</title>
         </MetaTags>

         <Hero />
         <AboutUs />
         
      </>
   }
}

export default Home;