
import Component from '@xavisoft/react-component';
import actions from '../actions';
import logger from '../logger';
import { ReactGA } from '../analytics';

class Page extends Component {

   componentDidMount() {
      try {
         let route;

         if (window.cordova) {
            route = window.location.hash.substring(1);
         } else {
            route = window.location.pathname;
         }

         actions.setCurrentRoute(route);
         window.scrollTo(0, 0);

         ReactGA.send({ 
            hitType: "pageview", 
            page: route, 
            title: document.title,
         });

      } catch (err) {
         logger.error(err);
      }
   }

   _render() {
      return <div>Please implement <code>_render()</code></div>
   }

   render() {
      return <main>
         <div className='page'>
            {this._render()}
         </div>
      </main>
   }

}

export default Page;