
import Component from '@xavisoft/react-component';
import bg from '../media/img/hero.jpg'
import { Button } from '@mui/material';
import { Link } from '@xavisoft/app-wrapper';



class Hero extends Component {
   
   render() {
      return <section id='hero-section'>
         <div
            style={{
               height: 'calc(var(--window-height) - var(--navbar-height))',
               backgroundImage: `url(${bg})`
            }}
         >
            <div className='fill-container' style={{ backgroundColor: 'rgb(0, 0, 0, 0.75)'}}>
               <div className='fill-container vh-align'>
                  <div style={{ maxWidth: 500, margin: 30 }}>
                     <div className='text-6xl text-white font-extrabold text-center'>
                        Make your WhatsApp bot in minutes.
                     </div>

                     <div className='mt-10 text-center'>
                        <Button variant='contained' size="large" className='rounded-3xl bg-[#3ab64a]' component={Link} to="/signup">
                           GET STARTED
                        </Button>
                        <Button variant='outlined' size="large" className='rounded-3xl ml-5 text-[#3ab64a]' component={Link} to="/documentation">
                           LEARN MORE
                        </Button>
                     </div>

                  </div>
               </div>
            </div>
         </div>
      </section>
   }
}


export default Hero;