
import Page from './Page'
import ReactMarkdown from 'react-markdown'

const policyContent = `
# Privacy Policy

Effective date: **17 May 2023**

We at Xavisoft Botmaker respect your privacy and are committed to protecting it through our compliance with this policy.

This policy applies to Xavisoft Botmaker platform and services, and describes the types of information we may collect from you or that you may provide when you use our services and how we use, disclose and protect that information.

## Information We Collect

We collect information from users including:

- Information you provide to us on **signup**, such as your name, email address, phone number and any other personal or business information you share with us when you create an account.

- Information about your business you provide as you configure your chatbot.

## How We Use Your Information

We may use the information we collect from you or that you provide to us to:

- Provide our services to you, including creating and managing your chatbot.

- Send you administrative messages, updates and promotional communications.

## Disclosure of Your Information

We do not disclose any information about you to third parties.

## Security

We use industry-standard measures to protect your personal information from unauthorized access, use or disclosure. However, no system can guarantee complete security.

## Data Retention

We will retain your personal information for as long as necessary to provide you with our services and as required by applicable laws and regulations.

**We do not store your customer interactions with your chatbot, at all.**

## Changes to Our Privacy Policy

We may update this policy at any time, especially when we add new features. We encourage you to review this policy periodically.

## Contact Us

If you have any questions or concerns about our privacy practices, please contact us on [our WhatsApp number](https://wa.me/263715919598).

`

export default class PrivacyPolicy extends Page {

   _render() {
      return <div className='py-6 px-[20px] md:px-[100px]'>
         <ReactMarkdown>{policyContent}</ReactMarkdown>
      </div>
   }
}