

const style = {
   position: 'relative',
   width: 300,
   aspectRatio: 385 / 854,
   margin: 'auto',
   border: '10px black solid',
   borderRadius: 36,
   overflow: 'hidden',
 }

export default function SamsungM04(props) {
   return <div style={style}>
      {props.children}
   </div>
}