


const ACTIONS = {
   ADD_BRANCH: 'add-branch',
   OPEN_BRANCH_EDITOR: 'open-branch-mode',
   CLOSE_BRANCH_EDITOR: 'close-branch-mode',
   DELETE_BRANCH: 'delete-branch',
   SET_DEVICE_INFO: 'set-device-info',
   SET_BUSINESS_INFO: 'set-business-info',
   SET_CURRENT_ROUTE: 'set-current-route',
   UPDATE_BRANCH: 'update-branch',
   UPDATE_BUSINESS_INFO: 'update-business-info',
}


const BREAKPOINTS = {
   SM: 600,
   MD: 900,
   LG: 1200,
   XL: 1536
}

const BRANCH_EDITOR_MODES = {
   EDIT: 'edit',
   ADD: 'add',
}

export {
   ACTIONS,
   BREAKPOINTS,
   BRANCH_EDITOR_MODES,
}