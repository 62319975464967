
import { MetaTags } from 'react-meta-tags';
import Page from './Page';
import DemoChatBotCarousel from '../components/DemoChatbotCarousel';
import { Link } from '@xavisoft/app-wrapper';
import configureChatScreenshot from '../media/img/configure-chat.png'
import testRunScreenshot from '../media/img/test-run.png'
import configureChatScreenshotMobile from '../media/img/configure-chat-mobile.png'
import testRunScreenshotMobile from '../media/img/test-run-mobile.png'
import createDeveloperAccountScreenshot from '../media/img/create-developer-account.png'
import emptyAppListScreenshot from '../media/img/empty-app-list.png'
import selectMetaAppUseCase from '../media/img/select-meta-app-use-case.png'
import selectMetaAppType from '../media/img/select-meta-app-type.png'
import addMetaAppDetails from '../media/img/add-meta-app-details.png'
import addProductToMetaApp from '../media/img/add-product-to-meta-app.png'
import addPhoneNumber from '../media/img/add-phone-number.png'
import setPrivacyPolicy from '../media/img/set-privacy-policy.png'
import configureWebhook from '../media/img/configure-webhook.png'


export default class Documentation extends Page {

   _render() {

      
      return <>
         <MetaTags>
            <title>Documentation | Xavisoft BotMaker</title>
         </MetaTags>

         <div className="p-7 md:mx-40 md:px-40" style={{ fontFamily: 'Arial' }}>
            
            <h1>Documentation</h1>

            <>
               <h2>Overview</h2>
               <p className="text-gray-600 text-2xl">
                  The Xavisoft BotMaker platform allows you organize information about your business so that your customers can interactively find it on WhatsApp, as shown below.
               </p>

               <DemoChatBotCarousel />
            </>

            <>
               <h2>Setting up chatbot</h2>
               
               <h3>1. Create account</h3>
               Go to <Link to="/signup">Sign Up</Link> page, create your account, then check your email to verify.

               <h3>2. Configure your chatbot</h3>

               <p>
                  Go to <Link to="/login">Login</Link> page, and login into your account.
               </p>

               <div className='w-[70%]'>
                  <img 
                     alt="Screenshot showing the 'Configure chatbot' screen"
                     src={configureChatScreenshot}
                     srcSet={`${configureChatScreenshot} 3582w, ${configureChatScreenshotMobile} 750w`}
                     className='w-[100%]'
                  />
               </div>

               <h4>Initial message</h4>
               <p>
                  This is the first message your customers get when they send you the first message.
               </p>

               <h4>Branches</h4>
               <p>
                  The branches are the options given to the customer to reply with. You can add sub-branches to create an information hierarchy.
               </p>

               <h3>3. Test your chatbot</h3>

               <p>
                  Test your chatbot configuration to get the feel of how customers will interact with it.
               </p>

               <div className='w-[70%]'>
                  <img 
                     alt="Screenshot showing the 'Test run' screen"
                     src={testRunScreenshot}
                     srcSet={`${testRunScreenshot} 3584w, ${testRunScreenshotMobile} 750w`}
                     className='w-[100%]'
                  />
               </div>
               
            </>

            <>
               <h2>Integrate with WhatsApp API</h2>
               This section assumes tha you already have a Facebook account. You will need to be on a device with a bigger screen for this part.

               <h3>1. Create Facebook app</h3>
               <p>
                  Go to <a href='https://developers.facebook.com'>https://developers.facebook.com</a>. Click <b>Get Started</b> to create a developer account
               </p>

               <div className='w-[70%]'>
                  <img 
                     alt="Screenshot showing the Meta Developers page"
                     src={createDeveloperAccountScreenshot}
                     className='w-[100%]'
                  />
               </div>

               <p>
                  Click <b>Create app</b> to start the process of creating the app.
               </p>

               <div className='w-[70%]'>
                  <img 
                     alt="Screenshot showing the option to create the Meta app"
                     src={emptyAppListScreenshot}
                     className='w-[100%]'
                  />
               </div>

               <p>
                  Select <b>Other</b> and then click <b>Next</b>.
               </p>

               <div className='w-[70%]'>
                  <img 
                     alt="Screenshot showing the Meta app use case options"
                     src={selectMetaAppUseCase}
                     className='w-[100%]'
                  />
               </div>

               <p>
                  Select <b>Business</b> and then click <b>Next</b>.
               </p>

               <div className='w-[70%]'>
                  <img 
                     alt="Screenshot showing the Meta app type options"
                     src={selectMetaAppType}
                     className='w-[100%]'
                  />
               </div>

               <p>
                  (You might need to <a href='https://business.facebook.com/overview'>create</a> an account in <b>Meta Business Manager</b> if you haven't done so before doing this step)
                  <br/>
                  Add your app details, select the business manager account, and then click <b>Create app</b>.
               </p>

               <div className='w-[70%]'>
                  <img 
                     alt="Screenshot showing the Meta app details screen"
                     src={addMetaAppDetails}
                     className='w-[100%]'
                  />
               </div>

               <p>
                  Scroll and find <b>WhatsApp</b> then click <b>Setup</b> under the WhatsApp card.
               </p>

               <div className='w-[70%]'>
                  <img 
                     alt="Screenshot showing the Meta app products"
                     src={addProductToMetaApp}
                     className='w-[100%]'
                  />
               </div>

               <h3>2. Add phone number</h3>

               <p>
                  Scroll down and click <b>Add Phone Number</b>. Follow the steps to add the phone number you want to use for the chatbot and verify it.
               </p>

               <div className='w-[70%]'>
                  <img 
                     alt="Add phone number"
                     src={addPhoneNumber}
                     className='w-[100%]'
                  />
               </div>


               <h3>3. Generate token</h3>

               <p>
                  To generate token, you first need to provide a <b>Privacy Policy URL</b> and set <b>App category</b>. 
                  <ol>
                     <li>On the Side Menu, go to <b>Settings &gt; Basic</b></li>
                     <li>Paste <a href='https://botmaker.xavisoft.co.zw/privacy-policy'>https://botmaker.xavisoft.co.zw/privacy-policy</a> in the Privacy Policy field.</li>
                     <li>Select <b>Messenger for bots</b> category.</li>
                     <li>Save.</li>
                  </ol>
               </p>

               <p>
                  You then need to set the <b>App Mode</b> to Live.
               </p>

               <div className='w-[70%]'>
                  <img 
                     alt="App Basic Settings"
                     src={setPrivacyPolicy}
                     className='w-[100%]'
                  />
               </div>

               <p>
                  Then follow the steps below to generate a token
               </p>

               <ol>
                  <li>Sign into the <a href='https://business.facebook.com'>Meta Business Suite</a></li>
                  <li>Locate your business account in the top-left dropdown menu and click its <b>Settings</b> (gear) icon</li>
                  <li>Click Business settings.</li>
                  <li>Navigate to User &gt; <b>System users</b>.</li>
                  <li>Select the appropriate system user from the list of system users (create one if you haven't).</li>
                  <li>Click the <b>Generate new token</b> button.</li>
                  <li>Set <b>Token expiration</b> to <b>never</b></li>
                  <li>Select the app that will use the token.</li>
                  <li>Select permissions: <b>whatsapp_business_messaging</b>, <b>whatsapp_business_management</b> and generate the token.</li>
                  <li>Copy and paste the token somewhere safe, you will need it in the following steps.</li>
               </ol>

               <h3>4. Set App Secret, Phone number ID, Access Token</h3>

               <p className='text-red-700'>Do not share these values with anyone, or save then somewhere somebody else can ccess</p>

               <b>Get app secret</b><br/>
               <p>
                  To get <b>App Secret</b>, select <b>Settings &gt; Basic</b> from the left menu. Show and copy the app secret
               </p>

               <b>Get Phone number ID</b><br/>

               <ol>
                  <li>From the left menu, select <b>WhatsApp &gt; Getting started</b></li>
                  <li>Scroll down to <b>Send and receive messages</b> section.</li>
                  <li>Set the <b>From</b> number to your WhatsApp Bot number.</li>
                  <li>Copy the <b>Phone number ID </b> below it</li>
               </ol>

               After you get the values, login to your <b>Xavisoft BotMaker</b> account, go to <b>Settings</b>, and put them under <b>Integration Settings</b> 

               <h3>5. Set up webhook</h3>
               After you're done with the the above step, you will see a new field labelled <b>Webhook URL</b>

               <ol>
                  <li>Copy the <b>Webhook URL</b> value</li>
                  <li>Go back to the Facebook developer portal.</li>
                  <li>One the left menu, select <b>WhatsApp &gt; Configuration.</b></li>
                  <li>Click <b>Edit</b>. You will be asked to provide <b>Callback URL</b> and <b>verification token</b>. Use the webhook url (copied from the BotMaker dashboard) and access token (generated earlier) respectively</li>
                  <li>Verify</li>
                  <li>Click <b>Manage</b></li>
                  <li>Subscribe to <b>messages</b> and click <b>Done</b>.</li>
               </ol>

               <div className='w-[70%]'>
                  <img 
                     alt="App Basic Settings"
                     src={configureWebhook}
                     className='w-[100%]'
                  />
               </div>

            </>

            <h2>5. Test</h2>
            <p>
               Send a WhatsApp message to your phone number. If you did everything correctly, your WhatsApp bot should be up and running. <a href='https://wa.me/263715919598'>Contact us</a> if you face any challenges.
            </p>
         </div>

      </>
   }
}