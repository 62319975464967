import { Button, Dialog, DialogContent, DialogTitle, TextField } from "@mui/material";
import Component from "@xavisoft/react-component";
import swal from "sweetalert";
import { hideLoading, showLoading } from "../loading";
import { errorToast } from "../toast";
import actions from "../actions";
import { delay } from "../utils";
import request from "../request";


export default class InitialMessageEditor extends Component {

   submit = async () => {

      const txtInitialMessage = document.getElementById('txt-initial-message');
      const initial_message = txtInitialMessage.value;

      if (!initial_message) {
         errorToast('Provide the initial message');
         return txtInitialMessage.focus();
      }

      try {

         showLoading();
         await request.patch('/api/businesses', { set: { initial_message }});

         actions.updateBusinessInfo({ initial_message });
         this.props.close();

      } catch (err) {
         swal(String(err));
      } finally {
         hideLoading();
      }
   }

   async componentDidUpdate(prevProps) {
      if (this.props.open && !prevProps.open) {
         await delay(50);
         document.getElementById('txt-initial-message').value = this.props.initial_message;
      }
   }

   render() {

      return <Dialog open={this.props.open}>

         <DialogTitle>Initial message</DialogTitle>

         <DialogContent>
            <TextField
               id="txt-initial-message"
               variant="standard"
               size="small"
               label="Initial message"
               fullWidth
               InputLabelProps={{ shrink: true }}
               multiline
            />

         </DialogContent>

         <DialogContent>
            <Button onClick={this.submit} variant="contained">
               SUBMIT
            </Button>
            <Button onClick={this.props.close}>
               close
            </Button>
         </DialogContent>
      </Dialog>
   }
}